import IFilmnytt from "src/lib/types/Filmnytt";
import { IFilmomtale } from "src/lib/types/Filmomtale";
import IMovieList from "src/lib/types/MovieList";
import { SanityDocument } from "next-sanity";
import { KeyedObject, TypedObject } from "sanity";

export default interface IForsidenV2 extends SanityDocument {
    articleList?: (PublishableDocument | LayoutObject)[];
    mostReadNewsArticleList?: IFilmnytt[];
}

export type ThemeValues = "dark" | "light" | "lightGradient" | "gold";

const DocType_Forsiden = "frontpage-v2";

const ItemType_StreamingSearchBox = "streamingSearchBox";
const ItemType_PosterList = "posterList";
const ItemType_TopBlock = "topBlock";
const ItemType_BlockWithAd = "blockWithAd";
const ItemType_ArticleRowBlock = "articleRowBlock";
const ItemType_ArticleRowBlock24 = "articleRowBlock2-4";
const ItemType_SquareArticleRowBlock = "squareArticleRowBlock";
const ItemType_ListPosterRow = "listPosterRow";
const ItemType_VerticalArticleListGroup = "verticalArticleListGroup";
const ItemType_VerticalArticleList = "verticalArticleList";
const ItemType_MostReadNews = "mostReadNews";
const ItemType_ArticlesAndList = "articlesAndList";

export const CountableItemTypes = [DocType_Forsiden,
    ItemType_ArticleRowBlock,
    ItemType_ArticleRowBlock24,
    ItemType_BlockWithAd,
    ItemType_ListPosterRow,
    ItemType_PosterList,
    ItemType_SquareArticleRowBlock,
    ItemType_StreamingSearchBox,
    ItemType_TopBlock,
    //ItemType_VerticalArticleList,
    ItemType_VerticalArticleListGroup,
    ItemType_ArticlesAndList
];

export {
    DocType_Forsiden,
    ItemType_ArticleRowBlock,
    ItemType_ArticleRowBlock24,
    ItemType_BlockWithAd,
    ItemType_ListPosterRow,
    ItemType_PosterList,
    ItemType_SquareArticleRowBlock,
    ItemType_StreamingSearchBox,
    ItemType_TopBlock,
    ItemType_VerticalArticleList,
    ItemType_MostReadNews,
    ItemType_VerticalArticleListGroup,
    ItemType_ArticlesAndList
};

export interface ITopBlock extends KeyedObject, TypedObject {
    _type: "topBlock";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
}

export interface IBlockWithAd extends KeyedObject, TypedObject {
    _type: "blockWithAd";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
}

export interface IStreamingSearchBox extends KeyedObject, TypedObject {
    _type: "streamingSearchBox";
    showSgLogo?: boolean; // default to true, so if not explicitly set to false, it should show the lodo
};

export interface IPosterList extends KeyedObject, TypedObject {
    _type: "posterList";
    title: string;
    movies: PublishableDocument[];
}

export interface IArticleRowBlock extends KeyedObject, TypedObject {
    _type: "articleRowBlock";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
    themes2?: ThemeValues;
}

export interface IArticleRowBlock24 extends KeyedObject, TypedObject {
    _type: "articleRowBlock2-4";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
    themes2?: ThemeValues;
    themes3?: ThemeValues;
}

export interface ISquareArticleRowBlock extends KeyedObject, TypedObject {
    _type: "squareArticleRowBlock";
    themes0?: ThemeValues;
    themes1?: ThemeValues;
}

export interface IMovieListBlock extends TypedObject, KeyedObject {
    _type: "listPosterRow";
    title: string;
    extLogoUrl?: string;
    extLogoLink?: string;
}

export interface IVerticalArticleListGroup extends TypedObject, KeyedObject {
    _type: "verticalArticleListGroup"
}

export interface IVerticalArticleList extends TypedObject, KeyedObject {
    _type: "verticalArticleList";
    label: string;
    themes?: ThemeValues;
    showSeparators?: boolean;
    articles: IFilmnytt[];
}

export interface IMostReadNews extends TypedObject, KeyedObject {
    _type: "mostReadNews";
    label: string;
    themes?: ThemeValues;
    showSeparators?: boolean;
    articles: IFilmnytt[];
}

export interface IArticlesAndListGroup extends TypedObject, KeyedObject {
    _type: "articlesAndList",
    themes0?: ThemeValues;
    themes1?: ThemeValues;
    themes2?: ThemeValues;
    themes3?: ThemeValues;
}

export type LayoutObject = ITopBlock | IBlockWithAd | IStreamingSearchBox | IPosterList | IArticleRowBlock | ISquareArticleRowBlock | IMovieListBlock | IArticleRowBlock24 | IVerticalArticleListGroup | IVerticalArticleList | IArticlesAndListGroup | IMostReadNews;

type GroupableLayoutObject = Exclude<LayoutObject, IStreamingSearchBox>;

export type GroupedLayoutObject<TLayout extends GroupableLayoutObject = GroupableLayoutObject> = TLayout & {
    articles?: PublishableDocument[];
}

export type GroupedLayoutObjectWithSubgroups<TLayout extends GroupableLayoutObject = GroupableLayoutObject, TSubLayout extends GroupableLayoutObject = GroupableLayoutObject> = TLayout & {
    layouts?: GroupedLayoutObject<TSubLayout>[];
};

export type GroupedLayoutObjectWithSubgroupsAndArticles<TLayout extends GroupableLayoutObject = GroupableLayoutObject, TSubLayout extends GroupableLayoutObject = GroupableLayoutObject> = GroupedLayoutObject<TLayout> & GroupedLayoutObjectWithSubgroups<TLayout, TSubLayout>;

export interface IArticlesAndList extends GroupedLayoutObjectWithSubgroupsAndArticles<IArticlesAndListGroup, IVerticalArticleList | IMostReadNews> {
    sectionTitle?: string;
}

export type PublishableDocument = IFilmnytt | IFilmomtale | IMovieList;